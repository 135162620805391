<template>
    <div>
        <v-title title="Application Groups - Add"></v-title>
        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Add group</h1>
                </div>
            </div>
        </title-box>

        <form @submit.prevent="submitForm">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-7">
                        <mercur-card class="mt-n4 mb-4">
                            <mercur-input v-model="form.groupName" :class="getValidationClass($v, 'groupName')">
                                Applications group name
                                <template #note>
                                    <span class="form-error" v-if="!$v.form.groupName.required">Group name is required</span>
                                </template>
                            </mercur-input>
                        </mercur-card>
                        <mercur-card class="mb-4 card--scrollable">
                            <h2 class="mt-1 font-weight-normal">Applications</h2>
                            <table class="table table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Application override</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="application in items" :key="application.applicationId">
                                    <td>{{ application.applicationName }}</td>
                                    <td>
                                        <mercur-checkbox v-model="application.applicationStatus" :id="`application-${application.applicationId}`">Is allowed</mercur-checkbox>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </mercur-card>
                        <div class="text-right mt-4">
                            <mercur-button class="btn" to="/groups">Cancel</mercur-button>
                            <mercur-button class="btn btn-primary" type="submit" :disabled="loading">Add group</mercur-button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import ResourcesView from '@/views/ResourcesView'
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { required } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'

export default {
    name: 'AddGroup',
    mixins: [ResourcesView, FormMixin],
    data () {
        return {
            url: null,
            action: CONFIG.API.ROUTES.APPLICATIONS_GROUPS.ADD,
            form: {
                applications: {},
            },
            isNotApplicationBound: true,
        }
    },
    validations: {
        form: {
            groupName: {
                required,
            },
        },
    },
    computed: {
        ...mapState('auth', ['user']),
        selectedApplications () {
            let arr = []
            this.items.forEach((item) => {
                if (item.applicationStatus) {
                    arr.push(item.applicationName)
                }
            })
            return arr
        },
    },
    methods: {
        submitForm () {
            this.form.applications.applicationNames = this.selectedApplications

            this.submit(this.action, this.form).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Group was added',
                })
                setTimeout(() => {
                    this.$router.push({
                        name: 'GroupsWrapper',
                    })
                }, 1000)
            })
        },
    },
    created () {
        this.url = CONFIG.API.ROUTES.APPLICATIONS.OVERVIEW
    },
}
</script>

<style lang="scss" scoped>
.card--scrollable {
    height: calc(100vh - 400px);
    overflow-y: auto;
}
</style>
